.slogan {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    color: var(--light-green);
    font-size: 18px;
}
.navbar {
    border-bottom: 3px solid var(--light-blue);
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: 3rem;
    margin-right: 3rem;
    display: flex;
    justify-content: space-between;
}
ul {
    list-style: none;
}
li {
    display: inline-block;
}
.navbar ul button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 10px;
    margin-right: 3rem;
    position: relative;
}
.navbar ul button > p {
    color: var(--gray);
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 21px;
    letter-spacing: -0.53px;
}
.navbar ul button:hover > p {
    color: #494949;
}
.navbar ul img {
    transition: all 0.3s ease-in-out;
}
.navbar ul button.expand:hover img {
    rotate: 90deg;
    filter: brightness(80%) sepia(100%) hue-rotate(120deg) saturate(120%)
}
.navbar ul button.expand:hover > ul {
    color: var(--gray);
    display: inline-flex;
}
.navbar ul button.expand > ul:hover {
    color: var(--gray);
    display: inline-flex;
}
.navbar ul button.expand > ul {
    transform: translate(0px, 35px);
    height: 70px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    display: flex;
    align-items: flex-end;
    color: transparent;
    display: none;
    position: absolute;
    gap: 30px;
}
.navbar .expand button {
    margin: 0px;
}
.navbar ul li {
    color: var(--gray);
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 19px;
    letter-spacing: -0.53px;
    white-space: nowrap;
}
.navbar ul li:hover {
    color: #494949;
}
.navbar ul button:hover img {
    transform: translate(3px, 0px);
    filter: brightness(80%) sepia(100%) hue-rotate(120deg) saturate(120%)
}