:root {
   /* []=========================[ COLORS ]=========================[] */
   --light-green: #b0e59a;
   --green: #78bf5a;
   --half-dark-green: #2aac88;
   --darker-green: #3eb488;
   --slight-dark-green: #2ca17f;
   --dark-green: #1c765d;
   --very-dark-green: #154435;
   --gray: #727675;
   --dark-gray: #262626;
   --black: #212121;
   --aqua: #c4e7f8;
   --light-blue: #e5f3fa;
   --turquoise: #13b2b1;
   --dark-turquoise: #2aac88;
   --very-dark-turquoise: #1d9a9a;
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.modal {
   position: fixed;
   z-index: 1;
   left: 0;
   top: 0;
   width: 100vw;
   height: 100vh;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.79);
}

.modal-content {
   background-color: rgba(0, 0, 0, 0.82);
   padding: 60px;
   height: 80vh;
   width: 60vw;
   overflow-y: auto;
   overflow-x: hidden;
   border-radius: 20px;
   position: relative;
}

.modal-content h1 {
   font-family: 'Manrope', sans-serif;
   font-size: 3rem !important;
   letter-spacing: -0.8px;
   text-align: left;
   color: white !important;
}
.modal-content button {
   font-family: fangsong;
   font-size: 50px;
   color: white !important;
   background-color: transparent;
   border: none;
   cursor: pointer;
   position: absolute;
   top: 15px;
   right: 15px;
   transform: rotate(45deg);
   font-weight: lighter;
   transition: all ease 0.3s;
   line-height: 30px;
}
.modal-content button:hover {
   transform: rotate(45deg) scale(1.2);
}
.modal-content p {
   font-family: 'Manrope', sans-serif;
   font-size: 2rem !important;
   letter-spacing: -0.8px;
   text-align: left;
   color: white;
}
.modal-content h2 {
   color: var(--light-green) !important;
   
   font-size: 2rem !important;
   letter-spacing: -0.8px;
   text-align: left;
}
.modal-content img {
   border-radius: 20px;
   border: 1px solid var(--light-green);
   max-width: 300px;
   text-align: right;
}
