.HIDE-CONTENT-NOT-READY {
   display: none !important;
}
a {
   text-decoration: none;
}
/* [ ]====================[ BUTTONS ]====================[ ] */
button {
   cursor: pointer;
   transition: all 0.3s ease;
   background: none;
   box-shadow: none;
   border: none;
   padding: 0px;
   text-align: left;
}

button.primary {
   color: white;
   border: 0px;
   font-family: 'Ubuntu', sans-serif;
   font-weight: 700;
   white-space: nowrap;
   font-size: 21px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: var(--green);
   width: 182px;
   height: 44px;
   border-radius: 20px;
   letter-spacing: -0.42px;
}

button.primary:hover {
   background-color: var(--turquoise);
   font-size: 22px;
}

button.secondary p {
   color: white;
   font-size: 32px;
   font-family: 'Manrope', sans-serif;
   font-weight: 700;
}

button.secondary {
   width: 100%;
   border: 0px;
   box-sizing: border-box;
   border-radius: 20px;
   padding: 15px;
   padding-left: 30px;
   padding-right: 25px;
   cursor: pointer;
   background-color: var(--turquoise);
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 20px;
   transition: all 0.5s ease;
   overflow: hidden;
}

button.secondary div {
   width: 80px;
   min-width: 80px;
   display: flex;
   align-items: flex-start;
}

button.secondary:hover {
   background-color: #20c4c4;
}

button.secondary img.arrow {
   transition: all 0.5s ease;
}

button.secondary:hover img.arrow {
   margin-left: 12px;
}

button.tertiary {
   color: white;
   border: 0px;
   font-family: 'Ubuntu', sans-serif;
   font-weight: 700;
   white-space: nowrap;
   font-size: 21px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: var(--very-dark-turquoise);
   width: 270px;
   height: 44px;
   border-radius: 20px;
   letter-spacing: -0.42px;
}

button.tertiary:hover {
   background-color: #1b8888;
   font-size: 22px;
}

/* [ ]====================[ TITLE ]====================[ ] */
.title {
   color: var(--dark-green);
   flex-wrap: wrap;
   display: flex;
   margin: 0px;
   font-family: 'Ubuntu', sans-serif;
   font-weight: 500;
   letter-spacing: -3.4px;
}

.subtitle {
   margin: 0px;
   color: var(--black);
   font-family: 'Manrope', sans-serif;
   font-weight: 500;
   letter-spacing: -0.9px;
}

h1 {
   font-size: 68px;
}

h2 {
   font-size: 60px;
   letter-spacing: -3px;
}

h3 {
   color: var(--half-dark-green) !important;
   font-size: 48px;
   letter-spacing: -2.4px;
}

h4 {
   font-size: 36px;
}

h5 {
   color: var(--aqua) !important;
   font-size: 130px;
   line-height: 100px;
}

h6 {
   font-size: 40px;
   letter-spacing: -2px;
}

/* [ ]====================[ TEXT ]====================[ ] */
p {
   margin: 0px;
   padding: 0px;
}

/* [ ]====================[ LIST ]====================[ ] */
ul {
   margin: 0px;
   padding: 0px;
}

/* [ ]====================[ EXTRA ]====================[ ] */
.noUnderline {
   text-decoration: none;
}

/* [ ]====================[ IMAGE HOVER ]====================[ ] */
.person {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 2.5rem;
}

.person > div {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 10px;
}

.person > div div {
   position: relative;
   overflow: hidden;
}

.person-story {
   border-radius: 20px;
   cursor: pointer;
   height: 100%;
}

.person-story > div {
   opacity: 0;
   transition: all 0.3s ease-in-out;
   height: calc(100% - 100px) !important;
}

.person-story:hover > div {
   opacity: 1;
   background-color: #032200ad;
}

.person-story img {
   height: inherit;
}

.person > div div.person-story > div {
   position: absolute;
   padding: 50px;
}

.person > div div.person-story > div p {
   text-align: left;
   color: white;
}

.person-story div > p:nth-child(1),
.person-story > p:nth-child(1) {
   font-family: 'Manrope', sans-serif;
   font-weight: 300;
   font-size: 32px;
   letter-spacing: -0.8px;
   text-align: left;
}

.person-story div > p:nth-child(2),
.person-story > p:nth-child(2) {
   font-family: 'Ubuntu', sans-serif;
   font-style: italic;
   font-weight: 300;
   font-size: 24px;
   color: var(--light-green) !important;
   letter-spacing: -0.8px;
   text-align: left;
}

.person-story div > p:nth-child(3),
.person-story > p:nth-child(3) {
   margin-top: 30px;
   font-family: 'Manrope', sans-serif;
   font-weight: 300;
   font-size: 1em;
   letter-spacing: -0.7px;
   text-align: left;
}

/* [ ]====================[ FINAL ]====================[ ] */
.final-container {
   margin-top: 11rem;
   margin-left: 25%;
   margin-right: 25%;
   display: flex;
   justify-content: space-between;
}
.final {
   width: 40%;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.final > div {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 20px;
   width: 100%;
   border-bottom: 3px solid var(--light-blue);
   padding-bottom: 20px;
   margin-bottom: 20px;
}
.final h3 {
   color: var(--dark-turquoise);
   font-family: 'Ubuntu', sans-serif;
   font-weight: 700;
   margin: 0px;
}
.final p {
   width: 85%;
   font-family: 'Manrope', sans-serif;
   font-weight: 500;
   font-size: 21px;
   color: var(--gray);
   margin-bottom: 50px;
}
