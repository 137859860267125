.donation-header {
   display: none;
}

@media screen and (max-width: 991px) {
   /* donation header */
   .donation-header {
      display: flex !important;
      top: 0px;
      position: sticky;
      background-color: #123a24e0;
      padding: 10px;
      justify-content: space-between;
      z-index: 100;
   }

   .donation-header img {
      filter: brightness(5);
   }

   /* footer */

   .social div:nth-child(2) {
      width: 100%;
   }

   .footer .social {
      gap: 0px !important;
      margin-top: 50px !important;
   }

   .footer .left {
      width: 0% !important;
   }

   .footer .right {
      width: 100% !important;
   }

   .footer .cloud {
      top: -100px !important;
   }

   .footer .cloud > div {
      min-width: 200px !important;
      width: 200px !important;
      min-height: 100px !important;
      height: 100px !important;
   }

   .footer .cloud p {
      font-size: 0.7em !important;
   }

   .footer .cloud button {
      margin-bottom: 30px !important;
   }

   .footer .cloud:hover img {
      margin-bottom: 7px !important;
   }

   .footer .cloud div {
      display: flex !important;
      align-items: flex-end !important;
   }

   .footer .cloud button img {
      height: 25px !important;
      margin-top: 0px !important;
   }

   .footer > div > div {
      margin-left: 30px !important;
      margin-right: 30px !important;
   }
   .footer {
      margin-top: 100px !important;
   }

   .footer .image-a {
      position: absolute !important;
      top: 80% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      z-index: -1 !important;
   }

   /* hamburger menu */
   .navbar {
      display: none !important;
   }
   .hamburger-react div {
      background-color: white !important;
   }
   .hamburger > div.close {
      right: -80% !important;
   }
   .hamburger > div {
      top: 0px;
      right: 0px;
      overflow: hidden;
      position: fixed;
      height: 100vh;
      width: 80%;
      transition: all 1s ease-in-out;
      z-index: 3;
   }
   .hamburger > div:nth-child(2).close {
      display: none;
   }
   .hamburger > div:nth-child(2) {
      opacity: 1;
      top: 0px;
      right: 0px;
      background-color: #00000071;
      height: 100vh;
      width: 200%;
      z-index: 2;
      transition: all 1s ease-in-out;
   }
   .hamburger > div:nth-child(2) > button {
      background-color: transparent;
      width: 100vh;
      height: 100vh;
   }
   .hamburger .column {
      background-color: #0f3328;
      height: 100%;
      width: 100%;
      z-index: 3;
   }

   .hamburger .column > div:nth-child(1) {
      max-width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: var(--very-dark-green);
      padding: 5%;
   }
   .hamburger .column > div > button:hover {
      filter: brightness(0.7);
   }
   .hamburger .column > div:nth-child(2) {
      display: flex;
      flex-direction: column;
   }
   .hamburger .elements {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
   }
   .hamburger .elements button {
      width: 100%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 5%;
      padding-top: 5%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      font-family: 'Manrope', sans-serif;
      color: white;
      font-weight: 500;
      font-size: 1.1em;
      letter-spacing: -0.6px;
   }
   .hamburger .elements button:hover {
      background-color: #0000003a;
   }

   .navigation .primary {
      display: none;
   }

   .navigation > div {
      gap: 0px !important;
   }

   .navigation > div > ul {
      width: 100% !important;
      justify-content: space-between;
   }

   /* general */
   .mob-hide {
      display: none !important;
   }

   .mob-gap-0 {
      gap: 0px !important;
   }

   /* mob font sizes */
   /*
      small: 1.1em
      medium: 1.5em
      large: 2.3em
  */

   /* general-individual element settings */
   h2,
   h3,
   .title {
      font-size: 2.3em !important;
      letter-spacing: -2px !important;
   }

   h4 {
      font-size: 1.5em !important;
   }

   p {
      font-size: 1.1em !important;
   }

   /* individual element settings */

   .intro div {
      margin-top: 20px;
   }

   .intro {
      margin: 20px !important;
      margin-top: 0px !important;
      flex-direction: column !important;
      gap: 1rem !important;
   }

   .intro > div:first-child {
      width: 100% !important;
      display: flex !important;
      justify-content: center !important;
   }

   .intro > div:nth-child(2) {
      width: 100% !important;
      margin-top: 0px !important;
   }

   .intro h4 {
      border: 0px !important;
      border-bottom: 3px solid var(--light-blue) !important;
      padding-bottom: 20px !important;
      margin-bottom: 0px !important;
   }

   .info {
      margin: 20px !important;
      margin-top: 50px !important;
      display: grid !important;
      grid-template-columns: repeat(1, 1fr) !important;
   }

   .info > div:nth-child(1),
   .info > div:nth-child(2),
   .info > div:nth-child(3) {
      width: 80% !important;
      margin-left: 10% !important;
      margin-right: 10% !important;
   }

   .info button {
      margin-bottom: 50px !important;
   }

   #t_patients {
      order: 1 !important;
   }

   #b_applynow {
      order: 1 !important;
   }

   #t_aboutus {
      order: 2 !important;
   }

   #b_learnmore {
      order: 2 !important;
   }

   #t_title {
      order: 3 !important;
   }

   #b_donate {
      order: 3 !important;
   }

   .video {
      margin: 0px !important;
      margin-top: 50px !important;
   }
   .video-container {
      border-radius: 0px !important;
   }

   .stats {
      gap: 20px !important;
      margin-top: 100px !important;
   }

   .stats > div {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 10px !important;
      width: 100% !important;
   }

   .stats > div div {
      border-radius: 20px !important;
      padding: 20px !important;
   }

   .stats h5 {
      line-height: 1 !important;
   }

   .stats img {
      width: 50px !important;
      height: 50px !important;
   }

   .mission {
      margin: 20px !important;
      margin-top: 100px !important;
      margin-bottom: 0px !important;
      flex-direction: column !important;
   }

   .mission div > div {
      align-items: center !important;
   }

   .mission div > div:nth-child(1) img {
      margin-top: 5px !important;
      width: 90px !important  ;
   }

   .mission > div {
      gap: 20px !important;
      width: 100% !important;
   }

   .mob-father {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: 100% !important;
   }
   .mob-father img {
      width: 100% !important;
   }

   .mission > div:nth-child(2) {
      display: none !important;
   }

   .mission button > img {
      width: 50px !important;
      height: 50px !important;
   }

   .person {
      margin: 0px !important;
      margin-top: 100px !important;
      gap: 5px !important;
   }

   .person h2 {
      margin-bottom: 15px !important;
   }

   .person > div {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 5px !important;
   }

   .person img {
      border-radius: 20px !important;
   }

   .person-story {
      padding: 20px !important;
      margin: 10px !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      background-color: var(--very-dark-green) !important;
      border-radius: 20px !important;
   }

   .person-story p:nth-child(1) {
      color: white !important;
      font-weight: 500 !important;
   }

   .person-story p:nth-child(3) {
      color: white !important;
      font-weight: 400 !important;
   }

   .person button:nth-child(1) img,
   .person button:nth-child(3) img,
   .person button:nth-child(6) img {
      display: none !important;
   }
   .person button:nth-child(4) > div {
      position: relative;
   }
   .person button:nth-child(4) > div > div {
      background-color: #033a13b0 !important;
      height: 100% !important;
      width: 100% !important;
      border-radius: 20px !important;
      position: absolute !important;
   }
   .person button:nth-child(4) > div > div > div {
      width: auto !important;
      height: 100% !important;
      padding: 30px !important;
      font-size: 1.5em !important;
      font-family: 'Manrope', sans-serif;
      color: white;
      font-weight: 500;
      font-size: 1.1em;
      letter-spacing: -0.6px;
   }

   .person button:nth-child(4) > div > div > div > p:nth-child(2) {
      color: var(--light-green) !important;
      font-style: italic;
      font-size: 0.8em !important;
      margin: 10px 0px !important;
   }

   .person-selected {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 97% !important;
      background-color: #033a13 !important;
      opacity: 0.6 !important;
      z-index: 1 !important;
      transition: all 0.3s ease-in-out !important;
   }

   .goal {
      margin: 20px !important;
      margin-top: 100px !important;
      flex-direction: column !important;
   }

   .mob-goal-img {
      display: flex !important;
      justify-content: center !important;
   }

   .goal > div:first-child,
   .goal > div:nth-child(2) {
      width: 100% !important;
   }

   .goal > div > img:first-child {
      display: none !important;
   }

   .goal img[alt='time'] {
      width: 90px !important;
   }

   .goal .container {
      padding: 0px !important;
      gap: 30px !important;
   }

   .goal img[alt='bulletBlossom'] {
      margin-top: 1px !important;
   }

   .goal .container ul {
      margin-bottom: 30px !important;
   }

   .goal .secondary > img {
      width: 50px !important;
      height: 50px !important;
   }

   .diseases {
      margin: 10px !important;
      margin-top: 100px !important;
      margin-bottom: 0px !important;
      gap: 20px !important;
   }

   .diseases h2 {
      text-align: left !important;
   }

   .diseases > img {
      width: 100px !important;
      height: 100px !important;
   }

   .types-content-container {
      display: flex !important;
   }
   .types-content-container > div {
      max-height: none !important;
      width: auto !important;
      padding: 20px !important;
   }

   .types-content-container button {
      display: none !important;
   }

   .types-content-container h6 {
      text-align: left;
      font-size: 1.5em !important;
      letter-spacing: 0em !important;
   }

   .types-items-container {
      flex-direction: column !important;
      gap: 10px !important;
   }

   .final-container {
      display: flex;
      flex-direction: column !important;
      gap: 50px !important;
      margin-top: 100px !important;
      margin-bottom: 0px !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
   }

   .final {
      width: 100% !important;
   }

   .final p {
      margin-bottom: 30px !important;
   }

   .story {
      margin-left: 20px !important;
      margin-right: 20px !important;
      margin-top: 20px !important;
      flex-direction: column !important;
   }

   .story div {
      gap: 20px !important;
      width: auto !important;
   }

   .story > div:nth-child(2) {
      order: -1;
      justify-content: center;
      align-items: center;
   }
   .story > div:nth-child(2) > img {
      width: 90%;
   }

   .goals {
      grid-template-columns: repeat(1, 1fr) !important;
      margin-left: 20px !important;
      margin-right: 20px !important;
      margin-top: 100px !important;
      gap: 30px !important;
   }

   .values {
      margin-top: 100px !important;
      margin-bottom: 0px !important;
      margin-left: 20px !important;
      margin-right: 20px !important;
      flex-direction: column;
   }

   .values > div {
      width: auto !important;
      gap: 30px !important;
   }

   .values > div:nth-child(2) {
      order: -1;
   }

   .banner {
      margin-top: 30px !important;
      margin-bottom: 0px !important;
      margin-left: 20px !important;
      margin-right: 20px !important;
      padding: 30px !important;
      flex-direction: column;
      gap: 30px !important;
   }

   .banner img {
      display: none !important;
   }

   .banner p {
      width: auto !important;
   }

   .leadership {
      margin-top: 30px !important;
      margin-bottom: 0px !important;
      margin-left: 20px !important;
      margin-right: 20px !important;
   }

   .leadership > div {
      margin-top: 0px !important;
   }

   .leadership > div > h2 {
      width: 100% !important;
      text-align: left !important;
   }

   .leadership > div > p {
      width: auto !important;
      text-align: left !important;
   }

   .leadership > div:nth-child(2) {
      margin-top: 30px !important;
   }

   .leadership button:nth-child(1),
   .leadership button:nth-child(2) {
      min-width: auto !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
   }

   .leadership-container {
      margin-top: 100px !important;
   }

   .person.mob {
      margin-top: 30px !important;
   }

   .person.mob > div {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
   }

   .person.mob > div > h2 {
      padding: 0px !important;
   }

   .person.mob .leadership-grid {
      display: flex !important;
      flex-direction: column !important;
   }

   .person.mob .person-story {
      padding: 0px !important;
   }

   .person.mob .person-story > div {
      padding: 20px !important;
      opacity: 1;
      background-color: #033a1398;
      font-size: 0.9em;
   }

   .general {
      margin-top: 100px !important;
      margin-left: 20px !important;
      margin-right: 20px !important;
      margin-bottom: 0px !important;
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 30px !important;
   }

   .general div {
      gap: 10px !important;
   }

   .general div > ul {
      gap: 20px !important;
   }

   .general div > ul img {
      margin-top: 2px !important;
   }
}
