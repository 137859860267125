.intro {
    display: flex;
    gap: 2.5rem;
    margin-left: 11rem !important;
    margin-right: 11rem !important;
    margin-top: 5rem !important;
}

.intro img {
    max-width: 100%;
    height: auto;
}

.intro h4.subtitle {
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-bottom: 1.4rem;
    border-bottom: 3px solid var(--light-blue);
}

.intro a {
    color: var(--darker-green);
}

.intro-bullets p {
    text-align: left;
    color: var(--gray);
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.6px;
}

.intro-bullets img {
    margin-top: 5px;
}

.info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    column-gap: 100px;
    margin-top: 11rem;
    margin-right: 11rem;
    margin-left: 11rem;
}

.info div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.info div.top {
    gap: 5px;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 3px solid var(--light-blue);
}

.info div.bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.info p {
    color: var(--gray);
    font-size: 21px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    margin-bottom: 25px;
    letter-spacing: -0.53px;
}

.video {
    margin-right: 11rem !important;
    margin-top: 11rem !important;
    margin-left: 11rem !important;
}

.video-container {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    overflow: hidden;
    border-radius: 20px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    margin-top: 11rem;
    margin-left: 10px;
    margin-right: 10px;
}

.stats > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.stats > div div {
    padding: 50px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 25px;
}

.stats p.description {
    color: white;
    text-align: left;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: -0.8px;
}

.stats p.signature {
    text-align: left;
    color: white;
    font-family: "Ubuntu", sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: -0.6px;
}

.mission {
    display: flex;
    gap: 2.5rem;
    margin-top: 11rem !important;
    margin-left: 11rem !important;
    margin-right: 11rem !important;
}

.mission p {
    text-align: left;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: var(--gray);
}

.mission img {
    max-width: 100%;
    height: auto;
}

.goal {
    display: flex;
    gap: 2.5rem;
    margin-top: 11rem ;
    margin-right: 11rem;
    margin-left: 11rem;
}

.goal .container {
    display: flex;
    padding: 3rem;
    flex-direction: column;
    gap: 2.5rem;
}

.goal img {
    max-width: 100%;
    height: auto;
}

.goal-bullets p {
    text-align: left;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 36px;
    letter-spacing: -0.6px;
}

.goal-bullets img {
    margin-top: 14px;
}
.types-content-container {
    /* change both rows to display: flex to do one item a row */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    width: 100%;
    gap: 10px;
}
.types-content-container > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
    width: 90%;
    padding: 5%;
}
.types-content-container h6 {
    text-align: left;
    color: var(--light-green);
}
.types-content-container button {
    background-color: var(--light-green);
}
.types-items-container {
    display: flex;
    gap: 50px;
}
.types-items-container > div {
    display: flex;
    gap: 20px;
    align-items: center;
}
.types-items-container p {
    text-align: left;
}
.types-items-container p.top-text {
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    font-size: 28px;
    letter-spacing: -0.7px;
    color: var(--light-green);
}
.types-items-container p.bottom-text {
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: white;
}
.types-content-container p.description {
    color: white;
    text-align: left;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 24px;
}

.diseases {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    margin-top: 11rem !important;
    margin-right: 11rem !important;
    margin-left: 11rem !important;
}
.disease-expand {
    overflow: hidden;
    transition: all 1s ease-in-out;
}
.desease-button {
    border: 0px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0px;
}
.desease-button p {
    color: var(--darker-green);
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-size: 18px;
}
.desease-button img {
    transition: all 0.3s ease-in-out;
}
.desease-button:hover img {
    margin-top: 13px;
}
.desease-button-rotate {
    margin-top: 13px;
    transform: rotate(180deg);
}
.desease-button-active:hover img {
    margin-top: 0px;
}
.desease-button div {
    width: 24px;
    height: 55px;
}
.person-home {
    margin-top: 11rem;
    margin-left: 10px;
    margin-right: 10px;
}
.patient-text {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 3rem;
}

