.footer {
    margin-top: 16rem;
    width: 100%;
    padding-top: 5rem;
}
.description {
    color: var(--slight-dark-green);
    text-align: left;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 21px;
    letter-spacing: -0.53px;
}
.navigation {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 3px solid var(--dark-green);
    border-top: 3px solid var(--dark-green);
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 20px;
}
.navigation div {
    display: flex;
    align-items: center;
    gap: 35px;
    width: 80%;
}
.navigation ul {
    list-style: none;
    display: flex;
    gap: 35px;
    list-style-type: none;
}
.navigation li {
    display: inline-block;
}
.navigation p {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 21px;
    letter-spacing: -0.53px;
    color: var(--slight-dark-green);
    transition: all 0.1s ease-in-out;
}
.navigation p:hover {
    color: var(--dark-green);;
}
.image-a {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.cloud {
    position: absolute;
    top: -170px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.cloud > div {
    width: 300px;
    height: 170px;
    min-width: 300px;
    min-height: 170px;
    max-width: 300px;
    max-height: 170px;
    background-image: url('../images/cloud.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cloud button {
    color: var(--darker-green);
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 13px;
}
.cloud img {
    rotate: 180deg;
    margin-top: 13px;
    transition: all 0.3s ease-in-out; 
}
.cloud button:hover {
    margin-top: 0px;
}
.cloud button div {
    height: 55px;
}
.cloud button:hover img {
    margin-top: 0px;
}
.social {
    display: flex;
    gap: 2.5rem;
}
.social a img {
    max-height: 100%;
}
.footer-logo {
    max-width: 100%;
}
.social div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
    justify-content: flex-end;
    gap: 35px;
}