.pointer {
  cursor: pointer;
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.flex.row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex.between {
  justify-content: space-between;
}

.flex.start {
  justify-content: start;
}

.flex.center {
  justify-content: center;
}

.flex.end {
  justify-content: end;
}

.flex.items-start {
  align-items: start;
}

.flex.items-center {
  align-items: center;
}

.flex.items-end {
  align-items: end;
}

.flex.wrap {
  flex-wrap: wrap;
}

.flex.gap-0 {
  gap: 0px;
}
.flex.gap-1 {
  gap: 0.25rem;
}
.flex.gap-015 {
  gap: 0.375rem; 
}
.flex.gap-2 {
  gap: 0.5rem;
}
.flex.gap-3 {
  gap: 0.75rem;
}
.flex.gap-4 {
  gap: 1rem;
}
.flex.gap-5 {
  gap: 1.25rem;
}
.flex.gap-6 {
  gap: 1.5rem;
}
.flex.gap-10 {
  gap: 2.5rem;
}
.flex.gap-11 {
  gap: 3rem;
}
.flex-gap-16 {
  gap: 4rem;
}

.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.h-100 {
  height: 100% !important;
}

.w-70 {
  width: 70% !important;
}

.w-50 {
  width: 50% !important;
}

.w-30 {
  width: 30% !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}


.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}


.mt-7 {
  margin-top: 5rem;
}

.mr-7 {
  margin-right: 5rem !important;
}

.mb-7 {
  margin-bottom: 5rem !important;
}

.ml-7 {
  margin-left: 5rem !important;
}

.mx-7 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.my-7 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mt-10 {
  margin-top: 8rem !important;
}

.mr-10 {
  margin-right: 8rem !important;
}

.mb-10 {
  margin-bottom: 8rem !important;
}

.ml-10 {
  margin-left: 8rem !important;
}

.mx-10 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.my-10 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}



.mt-13 {
  margin-top: 11rem !important;
}

.mr-13 {
  margin-right: 11rem !important;
}

.mb-13 {
  margin-bottom: 11rem !important;
}

.ml-13 {
  margin-left: 11rem !important;
}

.mx-13 {
  margin-right: 11rem !important;
  margin-left: 11rem !important;
}

.my-13 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important;
}


.mt-13 {
  margin-top: 13rem !important;
}


.mt-15 {
  margin-top: 16rem !important;
}

.mr-15 {
  margin-right: 16rem !important;
}

.mb-15 {
  margin-bottom: 16rem !important;
}

.ml-15 {
  margin-left: 16rem !important;
}

.mx-15 {
  margin-right: 16rem !important;
  margin-left: 16rem !important;
}

.my-15 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.mr-15p {
  margin-right: 15% !important;
}

.ml-15p {
  margin-left: 15% !important;
}

.mr-25p {
  margin-right: 25% !important;
}

.ml-25p {
  margin-left: 25% !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-8 {
  padding-top: 5rem !important;
}

/* [ ]====================[ KÂAN'S EXTENSION ]====================[ ] */
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.radius-3xl {
  border-radius: 20px;
}
.radius-3xl.left {
  border-radius: 0 1.5rem 1.5rem 0;
}
.radius-3xl.right {
  border-radius: 1.5rem 0 0 1.5rem;
}
.radius-3xl.top {
  border-radius: 1.5rem 1.5rem 0 0;
}
.radius-3xl.bottom {
  border-radius: 0 0 1.5rem 1.5rem;
}

.border {
  border: 2px solid red;
  border-style: dashed;
}
.border-b {
  border: 2px solid blue;
  border-style: dashed;
}
.border-c {
  border: 2px solid green;
  border-style: dashed;
}
.border-d {
  border: 2px solid orange;
  border-style: dashed;
}

/* [ ]====================[ PROJECT INDIVIDUAL EXTENSION ]====================[ ] */

.bg-dg {
  background-color: var(--dark-green);
}
.bg-hdg {
  background-color: var(--half-dark-green);
}
.bg-g {
  background-color: var(--green);
}
.bg-tur {
  background-color: var(--turquoise);
}
.bg-vdg {
  background-color: var(--very-dark-green);
}

.c-dtur {
  color: var(--dark-turquoise);
}