@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

.text-200 {
	font-family: "Manrope", sans-serif;
    font-weight: 200;
}
.text-300 {
	font-family: "Manrope", sans-serif;
    font-weight: 300;
}
.text-400 {
	font-family: "Manrope", sans-serif;
    font-weight: 400;
}
.text-500 {
	font-family: "Manrope", sans-serif;
    font-weight: 500;
}
.text-600 {
	font-family: "Manrope", sans-serif;
    font-weight: 600;
}
.text-700 {
	font-family: "Manrope", sans-serif;
    font-weight: 700;
}
.text-800 {
	font-family: "Manrope", sans-serif;
    font-weight: 800;
}


.text-300-ubuntu {
	font-family: "Ubuntu", sans-serif;
    font-weight: 300;
}
.text-400-ubuntu {
	font-family: "Ubuntu", sans-serif;
    font-weight: 400;
}
.text-500-ubuntu {
	font-family: "Ubuntu", sans-serif;
    font-weight: 500;
}
.text-700-ubuntu {
	font-family: "Ubuntu", sans-serif;
    font-weight: 700;
}
.text-300-ubuntu-italic {
	font-family: "Ubuntu", sans-serif;
	font-style: italic;
    font-weight: 300;
}
.text-400-ubuntu-italic {
	font-family: "Ubuntu", sans-serif;
	font-style: italic;
    font-weight: 400;
}
.text-500-ubuntu-italic {
	font-family: "Ubuntu", sans-serif;
	font-style: italic;
    font-weight: 500;
}
.text-700-ubuntu-italic {
	font-family: "Ubuntu", sans-serif;
	font-style: italic;
    font-weight: 700;
}

.test-stop-select {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}