.story p {
    text-align: left;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: var(--gray);
}

.story img {
    max-width: 100%;
    height: auto;
}

.goals {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    column-gap: 100px;
}

.goals div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.goals div.top {
    gap: 5px;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 3px solid var(--light-blue);
}

.goals p {
    color: var(--gray);
    font-size: 21px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    letter-spacing: -0.53px;
}

.values p {
    text-align: left;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: var(--gray);
}

.values img {
    max-width: 100%;
    height: auto;
}

.banner {
    padding: 50px;
    background-color: var(--turquoise);
    margin-left: 11rem;
    margin-right: 11rem;
    margin-top: 8rem;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.banner div {
    display: flex;
    gap: 40px;
    align-items: center;
}

.banner p {
    color: white;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: -0.8px;
    text-align: left;
    width: 65%;
}

.leadership-container {
    margin-top: 8rem;
}

.leadership {
    margin-left: 11rem;
    margin-right: 11rem;
}

.leadership-container > div > p {
    color: var(--gray);
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.8px;
    width: 70%;
}

.tab-bar {
    width: auto;
    height: 3px;
    background-color: var(--light-blue);
}
.tab {
    border-top: 3px solid var(--light-blue);
    border-left: 3px solid var(--light-blue);
    border-right: 3px solid var(--light-blue);
    border-radius: 10px 10px 0px 0px;
    min-width: 390px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    transform: translate(0px, 3px);
    transition: none;
}
.tab p {
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    font-size: 24px;
    white-space: nowrap;
    color: var(--gray);
    text-transform: uppercase;
}
.tab-disable {
    border: 3px solid var(--light-blue);
    border-radius: 10px 10px 0px 0px;
    min-width: 390px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b5dbef;
    transform: translate(0px, 3px);
    transition: none;
    text-transform: uppercase;
}
.tab-disable:hover {
    background-color: #aed3e7;
}
.tab-disable p {
    font-family: "Ubuntu", sans-serif;
    font-weight: 600;
    font-size: 24px;
    white-space: nowrap;
    color: white;
}
.leadership-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}
.general {
    margin-left: 11rem;
    margin-right: 11rem;
    margin-top: 13rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    row-gap: 170px;
}
.general p.head-text {
    color: var(--turquoise);
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: -0.6px;
    text-align: left;
}
.general p.bottom-text {
    color: var(--gray);
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.6px;
    text-align: left;
}
.general img {
    margin-top: 5px;
}
.person {
    margin-left: 10px;
    margin-right: 10px;
}
.person > div:first-child {
    display: flex;
    align-items: center;
}
.story.about {
    display: flex;
    gap: 2.5rem;
    margin-top: 8rem;
    margin-left: 11rem;
    margin-right: 11rem;
}
.values.about {
    display: flex;
    gap: 2.5rem;
    margin-top: 8rem;
    margin-left: 11rem;
    margin-right: 11rem;
}
.goals.about {
    margin-top: 11rem;
    margin-left: 11rem;
    margin-right: 11rem;
}